import React, { useState, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import CButton from '../components/atoms/CButton'
import CSection from '../components/atoms/CSection'
import CErrorMessage from '../components/form/CErrorMessage'
import CInput from '../components/form/CInput'
import CTextArea from '../components/form/CTextArea'
import axios from '../api/axios'
import { useTranslation } from 'react-i18next'

interface ContactForm {
    firstName: string
    lastName: string
    email: string
    question: string
}
const CONTACT_URL = '/contact'

const PContact = () => {
    const { t } = useTranslation()
    const [formSend, setFormSend] = useState<boolean>(false)

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
    } = useForm<ContactForm>()

    const submitForm = async (data: ContactForm) => {
        // TODO WHAT TO DO WITH FORM
        //console.log(data)
        try {
            const response = await axios.post(CONTACT_URL, JSON.stringify({ data }), {
                headers: { 'Content-Type': 'application/json' },
                withCredentials: true,
            })
            console.log(response.status)
            reset()
            setFormSend(true)
        } catch (err) {
            console.log(err)
        }
    }

    return (
        <CSection className="min-h-[calc(100vh-340px)] bg-light-blue">
            <form onSubmit={handleSubmit(submitForm)}>
                <div className="flex flex-col items-center">
                    <div className="flex w-full flex-col gap-4 py-10 md:w-[400px]">
                        <div className="text-3xl font-bold text-blue">{t('formTitle')}</div>
                        <div className="">
                            <CInput
                                register={() =>
                                    register('firstName', {
                                        required: t('formFirstNameRequired'),
                                    })
                                }
                                placeholder={t('formFirstName')}
                                error={errors['firstName']?.message}
                            />
                            <CErrorMessage error={errors['firstName']?.message} />
                        </div>
                        <div className="">
                            <CInput
                                register={() =>
                                    register('lastName', {
                                        required: t('formLastNameRequired'),
                                    })
                                }
                                placeholder={t('formLastName')}
                                error={errors['lastName']?.message}
                            />
                            <CErrorMessage error={errors['lastName']?.message} />
                        </div>
                        <div className="">
                            <CInput
                                register={() =>
                                    register('email', {
                                        required: t('formEmailRequired'),
                                        pattern: {
                                            value: new RegExp(/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/),
                                            message: t('formEmailValid'),
                                        },
                                    })
                                }
                                placeholder={t('formEmail')}
                                error={errors['email']?.message}
                            />
                            <CErrorMessage error={errors['email']?.message} />
                        </div>
                        <div className="">
                            <CTextArea
                                register={() =>
                                    register('question', {
                                        required: t('formQuestionRequired'),
                                    })
                                }
                                rows={5}
                                placeholder={t('formQuestion')}
                                error={errors['question']?.message}
                            />
                            <CErrorMessage error={errors['question']?.message} />
                        </div>
                        <div>
                            <CButton>{t('sendButton')}</CButton>
                        </div>

                        {formSend && (
                            <div className="rounded-xl bg-blue p-3 text-white" onClick={() => setFormSend(false)}>
                                {/*TODO WAT VOOR TEXT MOET HIER*/}
                                {t('formSendMsg')}
                            </div>
                        )}
                    </div>
                </div>
            </form>
        </CSection>
    )
}

export default PContact
