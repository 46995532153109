import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

i18n.use(initReactI18next).init({
    debug: true,
    fallbackLng: 'nl',
    interpolation: {
        escapeValue: false, // not needed for react as it escapes by default
    },
    // language resources
    resources: {
        en: {
            translation: {
                homeTitle: 'Fast <br/> simple <br/> and clear',
                homeSubTitle: 'Makes the writing of <strong>legal</strong> documents easier.',
                welcome:
                    'Spend <strong>your time</strong> on the content. <strong>No manual corrections</strong> of references and an <strong>automatic overview</strong> of all attachments.',
                terms: 'Terms and conditions',
                home: 'Home',
                price: 'Pricing',
                privacy: 'Privacy policy',
                contact: 'Contact',
                useButton: 'Use immediately',
                demoButton: 'Schedule demo',
                explainerCardFaster:
                    'Insert (cross)references directly while writing. The numbering adapts automatically this saves time and mistakes!',
                explainerCardOverview:
                    'Automatic generated table overview containing all references in the right order including summary description',
                explainerCardWord: 'Integrated in Microsoft Word. Wroks both on Microsoft and Apple',
                explainerCardPdf:
                    'Insert PDFs directly into the Word-document. The PDFs are coupled to the references.',
                explainerCardTemplates: 'Legal templates. (Coming soon)',
                explainerCardSearch: 'Laws and court rulings (Coming soon).',
                demoText: 'Want to schedule a demo? Contact us',
                footerTerms: 'Terms and conditions',
                footerPrivacy: 'Privacy policy',
                explainerCardTitle: 'Why use SmartNumbering?',
                explainerCardFasterHeader: 'Write faster',
                explainerCardOverviewHeader: 'Clear overview',
                explainerCardWordHeader: 'Word app',
                explainerCardPdfHeader: 'Manage attachments',
                explainerCardTemplatesHeader: 'Templates',
                explainerCardSearchHeader: 'Search',
                featureListTitle: 'Integrated in Word',
                featureListItem1: 'Add description of the attachment',
                featureListItem2: 'Insert references directly, numbering is updated automatically.',
                featureListItem3: 'Automatic generated overview of all attachments in chronologic order.',
                featureListItem4: 'Easy to use cross references.',
                pricingText:
                    'Currently the demo-version is free to use. In the future however cost per user license will be charged.<br/><br/> For more information please contact us.',
                termsText:
                    'SmartNumbering is easy to use and therefore our terms and conditions are as well. These apply to all agreements made with us and for all who make use of the demo-account.',
                termsDownloadText: 'If you want to read the full terms and conditios they can be ',
                downloadLinkText: 'downloaded here',
                privacyText:
                    'SmartNumbering respects your privacy and personal data however sometimes we do need them. Our privacy policy states which data we use and how we process, store and protect them. This policy applies to our wesite and de Word addin (the App). SmartNumbering handles your personal data conform the Algemene Verordening Gegevensbescherming (AVG).',
                privacyDownloadText: 'If you want to read the full privacy policy it can be ',
                formTitle: 'Send us a message',
                formFirstName: 'Firstname',
                formLastName: 'Lastname',
                formEmail: 'youremail@address.nl',
                formQuestion: 'Question or other description',
                formFirstNameRequired: 'A firstname is required',
                formLastNameRequired: 'A lastname is required',
                formEmailRequired: 'A email is required',
                formEmailValid: 'Please provide a valid email address',
                formQuestionRequired: 'Please fill in a question or other description',
                formSendMsg: 'Request succesfully send, we will contact you as soon as possible.',
                sendButton: 'Send',
            },
        },
        nl: {
            translation: {
                homeTitle: 'Snel <br/> eenvoudig <br/> en duidelijk',
                homeSubTitle: 'Maakt het schrijven van <strong>juridische</strong> documenten makkelijker',
                welcome:
                    'Spendeer <strong>uw tijd</strong> aan de inhoud. <strong>Geen handmatige aanpassing</strong> van verwijzingen. Een <strong>geautomatiseerd overzicht</strong> van alle bijlagen.',
                terms: 'Algemene voorwaarden',
                privacy: 'Privacy beleid',
                home: 'Home',
                price: 'Prijzen',
                contact: 'Contact',
                useButton: 'Direct gebruiken',
                demoButton: 'Demo inplannen',
                explainerCardFaster:
                    'Voeg (kruis)verwijzingen direct in tijdens het schrijven. De nummering past zich automatisch aan, dit bespaart tijd en fouten!',
                explainerCardOverview:
                    'Automatisch een overzicht met alle bijlagen in juiste volgorde incl. omschrijving.',
                explainerCardWord: 'Het kan direct worden ingevoegd in Word. Werkt zowel voor Microsoft als Apple.',
                explainerCardPdf:
                    'Direct invoegen van PDFs in het Word-document. De PDFs zijn gekoppeld aan verwijzingen.',
                explainerCardTemplates: 'Juridische templates. (Coming soon)',
                explainerCardSearch: 'Wetteksten en uitspraken (Coming soon).',
                demoText: 'Demo inplannen? Neem contact op',
                footerTerms: 'Algemene voorwaarden',
                footerPrivacy: 'Privacy beleid',
                explainerCardTitle: 'Waarom SmartNumbering?',
                explainerCardFasterHeader: 'Schrijf sneller',
                explainerCardOverviewHeader: 'Duidelijk overzicht',
                explainerCardWordHeader: 'Word app',
                explainerCardPdfHeader: 'Beheer bijlages',
                explainerCardTemplatesHeader: 'Templates',
                explainerCardSearchHeader: 'Zoek functie',
                featureListTitle: 'Direct te gebruiken in Word',
                featureListItem1: 'Omschrijving van bijlage toevoegen',
                featureListItem2: 'Verwijzing direct invoegen, automatische update nummering',
                featureListItem3: 'Automatisch overzicht met alle bijlagen in de juiste volgorde',
                featureListItem4: 'Eenvoudig invoegen van kruisverwijzingen',
                pricingText:
                    'Op dit moment is de demo-versie gratis te gebruiken. In de toekomst zullen er per licentie kosten in rekening worden gebracht. <br/><br/> Voor meer informatie neem contact met ons op.',
                termsText:
                    'SmartNumbering is simpel in gebruik en daarom zijn de algemene voorwaarden dat ook. Deze gelden voor alle overeenkomsten die we met u sluiten en voor iedereen die van het demo-account gebruik maakt.',
                termsDownloadText: 'Mocht je de gehele algemene voorwaarden willen lezen, dan kan je die ',
                downloadLinkText: 'hier downloaden',
                privacyText:
                    'SmartNumbering respecteert uw privacy en persoonlijke data maar soms hebben we deze toch nodig. In deze privacyverklaring kunt u lezen welke gegevens we gebruiken en hoe we deze verwerken, opslaan en beveiligen. Deze verklaring is van toepassing op onze website en de Word Applicatie (de App). SmartNumbering behandelt uw persoonlijke gegevens conform de Algemene Verordening Gegevensbescherming (AVG).',
                privacyDownloadText: 'Mocht je de gehele privacy verklaring willen lezen, dan kan je die ',
                formTitle: 'Stuur ons een bericht',
                formFirstName: 'Voornaam',
                formLastName: 'Achternaam',
                formEmail: 'jouwemail@adres.nl',
                formQuestion: 'Vraag of omschrijving',
                formFirstNameRequired: 'Een voornaam is verplicht',
                formLastNameRequired: 'Een achternaam is verplicht',
                formEmailRequired: 'Een email is verplicht',
                formEmailValid: 'Vul een geldig email adres in',
                formQuestionRequired: 'Vul alstublieft een vraag of omschrijving in',
                formSendMsg: 'Verzoek succesvol verstuurd, we nemen z.s.m. contact met u op.',
                sendButton: 'Verstuur',
            },
        },
    },
})

export default i18n
