import React, { FC } from 'react'
import { MdClose } from 'react-icons/md'
import { useNavigate } from 'react-router-dom'
import { ERoutes } from '../../conf/routes'

const CMobileMenu: FC<{ close: () => void }> = ({ close }) => {
    const nav = useNavigate()
    const navigate = (route: ERoutes) => {
        nav(route)
        close()
    }
    return (
        <div className="fixed left-0 top-0 z-10 h-[100vh] w-[100vw] bg-blue text-white">
            <div className="me-4 mt-4 flex flex-row justify-end ">
                <MdClose size={48} onClick={close} />
            </div>
            <div className="flex flex-col items-center gap-8">
                <div className="text-2xl" onClick={() => navigate(ERoutes.HOME)}>
                    Home
                </div>
                <div className="text-2xl" onClick={() => navigate(ERoutes.PRICES)}>
                    Prijzen
                </div>
                <div className="text-2xl" onClick={() => navigate(ERoutes.CONTACT)}>
                    Contact
                </div>
            </div>
        </div>
    )
}

export default CMobileMenu
