import React from 'react'
import { useNavigate } from 'react-router-dom'
import CButton from '../components/atoms/CButton'
import CSection from '../components/atoms/CSection'
import CExplainerCard from '../components/CExplainerCard'
import { ERoutes } from '../conf/routes'
import { useTranslation, Trans } from 'react-i18next'
const PHome = () => {
    const navigate = useNavigate()
    const { t } = useTranslation()
    return (
        <>
            <CSection className="bg-light-grey" wide tall>
                <div className="flex h-full flex-col justify-between py-3 md:flex-row">
                    <div className="md:order-0 order-1 flex w-full flex-col items-center md:w-1/2 md:items-start md:justify-around">
                        <div>
                            <div className="text-start text-6xl font-bold text-blue md:text-start ">
                                <Trans i18nKey="homeTitle" />
                            </div>
                            <div className="pt-3 text-center text-2xl md:text-start">
                                <p>
                                    <Trans i18nKey="homeSubTitle" />
                                </p>
                            </div>
                        </div>
                        <div>
                            <CButton
                                className="bg-red hover:bg-red-light"
                                onClick={() => window.open(ERoutes.INSTALL, '_blank')}
                            >
                                {t('useButton')}
                            </CButton>
                        </div>
                    </div>
                    <div className="order-0 flex md:order-1 md:flex md:w-1/2">
                        <img src="./background.svg" alt="preview" />
                    </div>
                </div>
            </CSection>
            <CSection>
                <p className="py-12 text-center text-2xl">
                    <Trans i18nKey="welcome" />
                </p>
            </CSection>
            <CSection className="bg-blue text-white" wide tall>
                <div className="flex flex-col py-12 md:h-full md:flex-row">
                    <div className="flex w-full flex-col items-center justify-center md:w-4/6">
                        <h3 className="pb-4 text-2xl font-bold">{t('featureListTitle')}</h3>
                        <div className="w-500 md:w-[350px] lg:w-[600px]">
                            <img src="./smartnumbering2.svg" alt="preview" />
                        </div>
                    </div>
                    <div className="flex w-full flex-col items-center justify-center px-4 pt-8 md:w-2/6 md:items-start  md:px-0 md:pt-0">
                        <ul className="list-disc text-lg">
                            <li className="pb-2">{t('featureListItem1')}</li>
                            <li className="pb-2">{t('featureListItem2')}</li>
                            <li className="pb-2">{t('featureListItem3')}</li>
                            <li className="pb-2">{t('featureListItem4')}</li>
                        </ul>
                    </div>
                </div>
            </CSection>

            <CSection>
                <h2 className="py-6 text-center text-2xl font-bold text-blue">{t('explainerCardTitle')}</h2>
            </CSection>

            <CSection className="bg-light-grey" wide>
                <div className="grid grid-cols-2 gap-4 py-16 lg:grid-cols-3">
                    <CExplainerCard icon="clock" header={t('explainerCardFasterHeader')}>
                        {t('explainerCardFaster')}
                    </CExplainerCard>
                    <CExplainerCard icon="rooster" header={t('explainerCardOverviewHeader')}>
                        {t('explainerCardOverview')}
                    </CExplainerCard>

                    <CExplainerCard icon="word" header={t('explainerCardWordHeader')}>
                        {t('explainerCardWord')}
                    </CExplainerCard>

                    <CExplainerCard icon="pdf" header={t('explainerCardPdfHeader')}>
                        {t('explainerCardPdf')}
                    </CExplainerCard>

                    <CExplainerCard icon="template" header={t('explainerCardTemplatesHeader')} disabled>
                        {t('explainerCardTemplates')}
                    </CExplainerCard>

                    <CExplainerCard icon="search" header={t('explainerCardSearchHeader')} disabled>
                        <Trans i18nKey="explainerCardSearch" />
                    </CExplainerCard>
                </div>
            </CSection>

            <CSection>
                <div className="flex flex-row items-center justify-between py-8 ">
                    <h2 className="w-1/2 text-2xl font-semibold ">{t('demoText')}</h2>
                    <CButton className="bg-red hover:bg-red-light" onClick={() => navigate(ERoutes.CONTACT)}>
                        {t('demoButton')}
                    </CButton>
                </div>
            </CSection>
        </>
    )
}

export default PHome
