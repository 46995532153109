import React, { FC } from 'react'

interface ErrorMessage {
    error?: string
}

const CErrorMessage: FC<ErrorMessage> = ({ error }) =>
    error ? <div className="ps-2 pt-1 text-red ">{error}</div> : null

export default CErrorMessage
