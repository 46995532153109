import React, { FC, TextareaHTMLAttributes } from 'react'

interface TextareaProps extends TextareaHTMLAttributes<any> {
    error?: string
    register: () => any
}

const CTextArea: FC<TextareaProps> = ({ error, register, ...props }) => (
    <textarea
        {...props}
        {...register()}
        className={`form-input w-full rounded-3xl px-4 pt-2 focus:outline-none ${error ? 'border-2 border-red' : ''} ${
            props.className ?? ''
        }`}
    />
)

export default CTextArea
