import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import CButton from '../components/atoms/CButton'
import CSection from '../components/atoms/CSection'
import H1 from '../components/atoms/H1'
import { ERoutes } from '../conf/routes'
import { useTranslation, Trans } from 'react-i18next'

const PPricing = () => {
    const navigate = useNavigate()
    const { t } = useTranslation()
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <CSection className="min-h-[calc(100vh-340px)] py-16 text-blue">
            <div className="flex justify-center">
                <div className="flex w-[400px] flex-col gap-8 ">
                    <H1>{t('price')}</H1>
                    <p>
                        <Trans i18nKey="pricingText" />
                    </p>

                    <div className="mt-10 flex gap-8">
                        <CButton
                            className="bg-red hover:bg-red-light"
                            onClick={() => window.open(ERoutes.INSTALL, '_blank')}
                        >
                            {t('useButton')}
                        </CButton>
                        <CButton
                            className="border border-red bg-white !text-red hover:bg-red-light hover:!text-white"
                            onClick={() => navigate(ERoutes.CONTACT)}
                        >
                            {t('demoButton')}
                        </CButton>
                    </div>
                </div>
            </div>
        </CSection>
    )
}

export default PPricing
