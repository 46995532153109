import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { ERoutes } from '../../conf/routes'
import CButton from '../atoms/CButton'
import CHeaderLink from './CHeaderLink'
import { MdMenu } from 'react-icons/md'
import CMobileMenu from './CMobileMenu'
import { LANGUAGES } from '../../conf/lang'
import { useTranslation } from 'react-i18next'
const CHeader = () => {
    const { i18n, t } = useTranslation()

    const onChangeLang = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const lang_code = e.target.value
        i18n.changeLanguage(lang_code)
    }
    const navigate = useNavigate()
    const [menuOpen, setMenuOpen] = useState<boolean>(false)
    return (
        <div className="sticky top-0 flex flex-row justify-between bg-light-grey md:pe-12 md:ps-8">
            <div className="flex flex-row items-center justify-between gap-0 md:justify-start md:gap-6">
                <img className="py-2" src="./icon-64.svg" alt="logo" height="64px" width="64px" />
                <h1
                    className="cursor-pointer text-2xl font-semibold text-blue md:text-3xl"
                    onClick={() => navigate(ERoutes.HOME)}
                >
                    SmartNumbering
                </h1>
            </div>
            <div className="flex flex-row items-center gap-10 pe-3 md:hidden ">
                <MdMenu size={24} onClick={() => setMenuOpen(true)} />
                {menuOpen && <CMobileMenu close={() => setMenuOpen(false)} />}
            </div>
            <div className="hidden flex-row items-center gap-10 md:flex">
                <CHeaderLink text={t('home')} url={ERoutes.HOME} />
                <CHeaderLink text={t('price')} url={ERoutes.PRICES} />
                <select
                    className="cursor-pointer border-none bg-light-grey hover:text-light-blue focus:text-blue"
                    defaultValue={i18n.language}
                    onChange={onChangeLang}
                >
                    {LANGUAGES.map(({ code, label }) => (
                        <option className="cursor-pointer" key={code} value={code}>
                            {label}
                        </option>
                    ))}
                </select>
                <CButton onClick={() => navigate(ERoutes.CONTACT)}>{t('contact')}</CButton>
            </div>
        </div>
    )
}

export default CHeader
