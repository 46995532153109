import React, { FC, ButtonHTMLAttributes, PropsWithChildren } from 'react'

const CButton: FC<PropsWithChildren<ButtonHTMLAttributes<any>>> = ({ children, ...props }) => (
    <button
        {...props}
        className={`rounded-full bg-blue px-3 px-8 py-2 text-white hover:bg-blue-light ${props.className}`}
    >
        {children}
    </button>
)

export default CButton
