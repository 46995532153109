import React, { FC } from 'react'

export type IconName = 'clock' | 'rooster' | 'word' | 'pdf' | 'template' | 'search'

const CIcon: FC<{ name: IconName }> = ({ name }) => {
    const width = 50

    switch (name) {
        case 'clock':
            return <img src={'./write_faster.svg '} width={width} />
        case 'rooster':
            return <img src={'./table_logo.svg '} width={width} />
        case 'word':
            return <img src={'./word_logo.svg '} width={width} />
        case 'pdf':
            return <img src={'./pdf_logo.svg '} width={width} />
        case 'template':
            return <img src={'./template_logo.svg '} width={width} />
        case 'search':
            return <img src={'./search_logo.svg '} width={width} />
    }
}

export default CIcon
