import React, { useEffect } from 'react'
import CSection from '../components/atoms/CSection'
import H1 from '../components/atoms/H1'
import { useTranslation } from 'react-i18next'

const PPrivacy = () => {
    const { t } = useTranslation()
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <CSection className="min-h-[calc(100vh-340px)]  flex-grow py-16 text-blue">
            <div className="flex justify-center">
                <div className="flex w-[400px] flex-col gap-8 ">
                    <H1>{t('privacy')}</H1>
                    <p>{t('privacyText')}</p>
                    <p>
                        {t('privacyDownloadText')}
                        <a
                            href="https://www.smartnumbering.nl/privacy.pdf"
                            className="underline hover:text-blue-light"
                            target="_blank"
                            role="button"
                            rel="noreferrer"
                        >
                            {t('downloadLinkText')}
                        </a>
                        .
                    </p>
                </div>
            </div>
        </CSection>
    )
}

export default PPrivacy
