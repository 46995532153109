import React, { FC, PropsWithChildren } from 'react'

const CSection: FC<PropsWithChildren<{ className?: string; wide?: boolean; tall?: boolean }>> = ({
    children,
    className,
    wide = false,
    tall = false,
}) => (
    <div className={className}>
        <div
            className={`m-auto w-full px-4 md:px-0 ${wide ? 'md:w-[600px] lg:w-[1000px]' : 'md:w-[750px]'} ${
                tall ? 'md:h-[700px]' : 'md:h-[auto]'
            } `}
        >
            {children}
        </div>
    </div>
)

export default CSection
