import React from 'react'
import { ERoutes } from '../../conf/routes'
import CFooterSection from './CFooterSection'
import CFooterSectionHeader from './CFooterSectionHeader'
import CFooterSectionLink from './CFooterSectionLink'
import { useTranslation } from 'react-i18next'

const CFooter = () => {
    const { t } = useTranslation()
    return (
        <>
            <div className="flex flex-row items-end justify-center bg-blue px-4 pb-2 text-white md:px-0">
                <div className="flex flex-col items-start">
                    <div className="flex flex-col py-16 md:flex-row">
                        <CFooterSection className="hidden md:block">
                            <img src="./icon-w-64.svg" alt="logo" height="64px" width="64px" />
                        </CFooterSection>
                        <CFooterSection>
                            <CFooterSectionHeader>Links</CFooterSectionHeader>
                            <CFooterSectionLink text={t('footerTerms')} url={ERoutes.TERMS} />
                            <CFooterSectionLink text={t('footerPrivacy')} url={ERoutes.PRIVACY} />
                        </CFooterSection>

                        <CFooterSection>
                            <CFooterSectionHeader>Support</CFooterSectionHeader>
                            <CFooterSectionLink text="Contact" url={ERoutes.CONTACT} />
                        </CFooterSection>

                        <CFooterSection>
                            <CFooterSectionHeader>Social</CFooterSectionHeader>
                            {/*TODO LINKEDIN LINK*/}
                            <CFooterSectionLink
                                text="LinkedIn"
                                url="https://www.linkedin.com/company/smartnumbering/"
                                external
                            />
                        </CFooterSection>
                    </div>
                    <div>Copyright ©{new Date().getFullYear()} SmartNumbering. All rights reserved.</div>
                </div>
            </div>
        </>
    )
}
export default CFooter
