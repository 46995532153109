import React, { FC, PropsWithChildren } from 'react'
import CIcon, { IconName } from './atoms/CIcon'

interface ExplainerCardProps {
    icon: IconName
    header: string
    disabled?: boolean
}

const CExplainerCard: FC<PropsWithChildren<ExplainerCardProps>> = ({ header, icon, children, disabled }) => (
    <div
        className={`border-1 h-[275px] rounded-lg border bg-white p-3 sm:h-[250px] ${
            disabled ? 'text-[#AAA]' : 'text-blue'
        }`}
    >
        <div className="flex flex-row items-center gap-2 pb-4 font-bold">
            <CIcon name={icon} />
            <div>{header}</div>
        </div>
        <div>{children}</div>
    </div>
)

export default CExplainerCard
