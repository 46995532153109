import React from 'react'
import { Route, Routes } from 'react-router-dom'
import CFooter from './components/footer/CFooter'
import CHeader from './components/header/CHeader'
import { ERoutes } from './conf/routes'
import PContact from './pages/PContact'
import PHome from './pages/PHome'
import PPricing from './pages/PPricing'
import PPrivacy from './pages/PPrivacy'
import PTerms from './pages/PTerms'

function App() {
    return (
        <div className="App">
            <CHeader />
            <Routes>
                <Route path={ERoutes.HOME} element={<PHome />} />
                <Route path={ERoutes.CONTACT} element={<PContact />} />
                <Route path={ERoutes.PRICES} element={<PPricing />} />
                <Route path={ERoutes.PRIVACY} element={<PPrivacy />} />
                <Route path={ERoutes.TERMS} element={<PTerms />} />
                <Route path="/*" element={<div>404</div>} />
            </Routes>
            <CFooter />
        </div>
    )
}
export default App
