import React, { FC, PropsWithChildren } from 'react'
import { useNavigate } from 'react-router-dom'

interface FooterLinkProps {
    text: string
    url: string
    external?: boolean
}

const CFooterSectionLink: FC<PropsWithChildren<FooterLinkProps>> = ({ url, text, external = false }) => {
    const navigate = useNavigate()
    return external ? (
        <a className="mb-1 text-white hover:underline" href={url} target="_blank" rel="noreferrer">
            {text}
        </a>
    ) : (
        <button className="mb-1 text-white hover:underline" onClick={() => !external && navigate(url)}>
            {text}
        </button>
    )
}

export default CFooterSectionLink
