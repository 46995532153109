import React, { FC } from 'react'
import { useNavigate } from 'react-router-dom'

interface HeaderLinkProps {
    text: string
    url: string
}

const CHeaderLink: FC<HeaderLinkProps> = ({ url, text }) => {
    const navigate = useNavigate()
    return (
        <button className="text-blue hover:text-light-blue" onClick={() => navigate(url)}>
            {text}
        </button>
    )
}

export default CHeaderLink
